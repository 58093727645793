/** @format */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "behncscrift";
  src: url("./fonts/BAHNSCHRIFT.TTF");
}
@font-face {
  font-family: "calibriregular";
  src: url("./fonts/calibri/Calibri\ Regular.ttf");
}
@font-face {
  font-family: "calibribold";
  src: url("./fonts/calibri/Calibri\ Bold.TTF");
}
@font-face {
  font-family: "calibrilight";
  src: url("./fonts/calibri/Calibri\ Light.ttf");
}

ul {
  list-style-type: square;
  margin: 10px;
  padding: 20px;
}

ol {
  list-style-type: decimal;
  padding: 20px;
  margin: 10px;
}
